import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-magic-items"></a><h2>Epic Magic Items</h2>
While not truly an artifact, the epic magic item is a creation of such
power that it surpasses other magic items. Epic magic items are objects
of great power and value. The following are typical characteristics of
an epic magic item. In general, an item with even one of these
characteristics is an epic magic item. 
    <ul>
      <li>Grants a bonus on attacks or damage greater than +5.</li>
      <li>Grants an enhancement bonus to armor higher than +5.</li>
      <li>Has a special ability with a market price modifier greater
than +5.</li>
      <li>Grants an armor bonus of greater than +10 (not including
magic armor&rsquo;s enhancement bonus).</li>
      <li>Grants a natural armor, deflection, or resistance bonus
greater than +5.</li>
      <li>Grants an enhancement bonus to an ability score greater
than +6.</li>
      <li>Grants an enhancement bonus on a skill check greater than
+30.</li>
      <li>Mimics a spell of an effective level higher than 9th.</li>
      <li>Has a caster level above 20th.</li>
      <li>Has a market price above 200,000 gp, not including material
costs for
armor or weapons, material component- or experience point-based costs,
or additional value for intelligent items.</li>
    </ul>
    <p>An epic magic item that grants a bonus beyond those allowed
for normal magic items has a higher market price than indicated by the
formulas for non-epic items. </p>
    <p>Epic magic items are not artifacts. They are not unique,
though they are certainly very rare, and anyone with the proper item
creation feats can build them. Even an epic magic item can never grant
a dodge bonus, and the maximum inherent bonus that can be applied to an
ability score is +5. An epic magic item cannot be created that uses or
mimics an epic spell. A major artifact might be able to mimic such a
spell, however. </p>
    <a id="creating-epic-magic-items"></a><h6>CREATING EPIC MAGIC ITEMS </h6>
The process of creating an epic magic item is very similar to creating
a nonepic magic item. However, certain important differences exist. 
    <a id="caster-level"></a><h6>CASTER LEVEL </h6>
Spells with an effective level of 10th or higher are possible at epic
levels. Because these spell slots aren&rsquo;t automatically gained at
a
particular level like 0- to 9th-level spells are, they don&rsquo;t have
a
minimum caster level. For this reason, the minimum caster level for any
spell of 10th level or higher is set at 11 + spell level. 
    <a id="prerequisites"></a><h6>PREREQUISITES </h6>
In addition to the materials and tools required for nonepic magic
items, any epic magic item requires at least two item creation feats:
the epic and nonepic version.
    <a id="market-price"></a><h6>MARKET PRICE </h6>
Use the guidelines for nonepic magic items to determine the market
price of an epic magic item, with one addition: If the item gives a
bonus beyond the limit allowed in for normal, nonepic magic items,
multiply the portion of the market price derived from that
characteristic by 10. Some epic characteristics, such as caster level,
don&rsquo;t trigger this multiplier. 
    <a id="experience-point-cost"></a><h6>EXPERIENCE POINT COST </h6>
The experience point cost to create an epic magic item is determined
differently than for a normal magic item. For all epic magic items
other than scrolls, divide the market price by 100, then add 10,000 XP
to the result. The final number is the experience point cost to create
the item. 
    <p>{`For epic scrolls, divide the market price by 25 (as normal for creating
a nonepic scroll), then add 1,000 XP to the result. The final number is
the experience point cost to create the epic scroll. `}</p>
    <a id="magic-item-descriptions"></a><h6>MAGIC ITEM DESCRIPTIONS </h6>
In the following sections, each general type of magic item, such as
armor or scrolls, has an overall description, followed by descriptions
of specific items, if any. Each magic item description and table
follows the same format used for nonepic magic items. Specific
exceptions are noted as necessary.
    <a id="epic-magic-armor"></a><h3>ARMOR
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Except when otherwise stated, epic magic armor and shields follow the
rules for nonepic magic armor and shields.
    <p>There is no limit to the enhancement bonus of epic magic armor
or shields, to the market price modifier of epic magic armor or shield
special abilities, or to the total enhancement bonus and market price
modifier of epic magic armor or shields. </p>
    <a id="epic-armor-and-shield-base-price"></a><h5>EPIC ARMOR AND SHIELD BASE PRICE </h5>
To find the base price of an epic suit of magic armor or an epic magic
shield, roll on Table: Armor and Shields. Note that the +6 to +10 rows
apply only to armor and shields that provide an enhancement bonus of +6
to +10 or armor and shields with a single special ability whose market
price modifier is +6 to +10. Magic armor and shields with a total
effective bonus of +6 to +10 but that have an enhancement bonus of +5
or less and special abilities whose individual market price modifiers
are +5 or less use the table for nonepic magic armor to determine price.
    <a id="table-armor-and-shields"></a><table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}><b>Table: Armor and Shields</b>
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%
                  </th>
                  <th style={{
                    "width": "35%"
                  }}>Enhancement Bonus
                  </th>
                  <th style={{
                    "width": "20%"
                  }}>Base Price</th>
                </tr>
                <tr className="odd-row">
                  <td>01
                  </td>
                  <td>+1 shield<sup>1</sup></td>
                  <td>1,000 gp</td>
                </tr>
                <tr>
                  <td>02
                  </td>
                  <td>+1 armor<sup>2</sup></td>
                  <td>1,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>03
                  </td>
                  <td>+2 shield<sup>1</sup></td>
                  <td>4,000 gp</td>
                </tr>
                <tr>
                  <td>04
                  </td>
                  <td>+2 armor<sup>2</sup></td>
                  <td>4,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>05-06
                  </td>
                  <td>+3 shield<sup>1</sup></td>
                  <td>9,000 gp</td>
                </tr>
                <tr>
                  <td>07-08
                  </td>
                  <td>+3 armor<sup>2</sup></td>
                  <td>9,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>09-12
                  </td>
                  <td>+4 shield<sup>1</sup></td>
                  <td>16,000 gp</td>
                </tr>
                <tr>
                  <td>13-16
                  </td>
                  <td>+4 armor<sup>2</sup></td>
                  <td>16,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>17-21
                  </td>
                  <td>+5 shield<sup>1</sup></td>
                  <td>25,000 gp</td>
                </tr>
                <tr>
                  <td>22-26
                  </td>
                  <td>+5 armor<sup>2</sup></td>
                  <td>25,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>27-30
                  </td>
                  <td>+6 armor</td>
                  <td>360,000 gp
                  </td>
                </tr>
                <tr>
                  <td>31-34
                  </td>
                  <td>+6 shield
                  </td>
                  <td>360,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>35-38
                  </td>
                  <td>+7 armor
                  </td>
                  <td>490,000 gp
                  </td>
                </tr>
                <tr>
                  <td>39-42
                  </td>
                  <td>+7 shield
                  </td>
                  <td>490,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>43-45
                  </td>
                  <td>+8 armor
                  </td>
                  <td>640,000 gp
                  </td>
                </tr>
                <tr>
                  <td>46-48
                  </td>
                  <td>+8 shield
                  </td>
                  <td>640,000 gp
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>49-50
                  </td>
                  <td>+9 armor
                  </td>
                  <td>810,000 gp
                  </td>
                </tr>
                <tr>
                  <td className="last-row">51-52
                  </td>
                  <td className="last-row">+9 shield
                  </td>
                  <td className="last-row">810,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>53-54
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>+10
shield 
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>1,000,000
gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>55-56
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>+10 armor
                  </td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>1,000,000 gp
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>57-62</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }} rowSpan="1" colSpan="2">Specific armor or shield<sup>3</sup> 
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>63-75</td>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="2">Armor with special ability and roll again<sup>4</sup>
&nbsp;&nbsp;&nbsp; </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>76-98</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }} rowSpan="1" colSpan="2">Shield with special ability and roll again<sup>4</sup>
&nbsp; 
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>99</td>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="2">Epic shield<sup>5</sup></td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>100
                  </td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }} rowSpan="1" colSpan="2">Epic armor<sup>5</sup></td>
                </tr>
                <tr>
                  <td colSpan="3">1<span style={{
                      "fontStyle": "italic"
                    }}>
Roll also on nonepic magic item Table: Shield Special Abilities.
&nbsp;&nbsp;&nbsp; </span>
                    <p>{`2`}<span style={{
                        "fontStyle": "italic"
                      }}>{` Roll also on
nonepic magic item Table: Armor Special Abilities. `}{` `}{` `}{` `}{` `}</span></p>
                    <p>{`3`}<span style={{
                        "fontStyle": "italic"
                      }}>{` Roll on Table:
Specific Epic Magic Armor and Shields. `}{` `}{` `}{` `}{` `}</span></p>
                    <p>{`4`}<span style={{
                        "fontStyle": "italic"
                      }}>{` Roll on Table:
Armor Special Abilities or Table: Shield Special Abilities.
`}{` `}{` `}{` `}{` `}</span></p>
                    <p>{`5`}<span style={{
                        "fontStyle": "italic"
                      }}>{` Roll on Table:
Epic Armor and Shields. `}{` `}{` `}{` `}{` `}</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <a id="table-random-shield-type"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Random Shield Type
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "20%"
                  }}>d%</th>
                  <th style={{
                    "width": "50%"
                  }}>Shield</th>
                  <th style={{
                    "width": "30%"
                  }}>Shield Cost<sup>1</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01&ndash;10</td>
                  <td>Buckler</td>
                  <td>+165 gp</td>
                </tr>
                <tr>
                  <td>11&ndash;15</td>
                  <td>Shield, light, wooden<sup>2</sup></td>
                  <td>+153 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>16&ndash;20</td>
                  <td>Shield, light, steel<sup>3</sup></td>
                  <td>+159 gp</td>
                </tr>
                <tr>
                  <td>21&ndash;30</td>
                  <td>Shield, heavy, wooden<sup>2</sup></td>
                  <td>+157 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>31&ndash;95</td>
                  <td>Shield, heavy, steel<sup>3</sup></td>
                  <td>+170 gp</td>
                </tr>
                <tr>
                  <td className="last-row">96&ndash;100</td>
                  <td className="last-row">Shield, tower<sup>2</sup></td>
                  <td className="last-row">+180 gp</td>
                </tr>
                <tr>
                  <td colSpan="3"><i>All magic shields are masterwork
shields (with an armor check penalty 1 lower than normal).
&nbsp;&nbsp;&nbsp; 
                    </i>1<i> Add to enhancement bonus on Table: Epic
Armor and Shields to
determine total market price. &nbsp;&nbsp;&nbsp; 
                    </i>2<i> Roll d% to determine material: 01-70 wood;
71-99 dark-wood; 100
other. Adjust price accordingly. &nbsp;&nbsp;&nbsp; 
                    </i>3<i> Roll d% to determine material: 01-70 steel;
71-90 mithral; 91-99
adamantine; 100 other. Adjust price accordingly.
                    </i></td>
                </tr>
              </tbody>
            </table>
            <a id="table-shield-special-abilities"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Shield Special
Abilities</span>
            <table style={{
              "width": "500px"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr>
                  <th style={{
                    "width": "12%"
                  }}>d% </th>
                  <th style={{
                    "width": "44%"
                  }}>Special Ability </th>
                  <th style={{
                    "width": "10%"
                  }}>Market Price Modifier<sup>1</sup>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>01-06 </td>
                  <td>Great invulnerability (10/magic)
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>+4 bonus </td>
                </tr>
                <tr>
                  <td>07-12 </td>
                  <td>Great invulnerability (15/magic) </td>
                  <td style={{
                    "width": "10%"
                  }}>+5 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>13-19 </td>
                  <td> Acid warding</td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr>
                  <td>20-26 </td>
                  <td> Cold warding</td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>27-33 </td>
                  <td> Fire warding</td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr>
                  <td>34-37 </td>
                  <td> Great invulnerability (5/epic)</td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>38-43 </td>
                  <td> Great spell resistance (SR 21)</td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr>
                  <td>44-46 </td>
                  <td> Infinite arrow deflection
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>47-53 </td>
                  <td> Lightning warding</td>
                  <td style={{
                    "width": "10%"
                  }}> +6 bonus</td>
                </tr>
                <tr>
                  <td>54-60 </td>
                  <td> Sonic warding</td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>61-63 </td>
                  <td> Great invulnerability (10/epic)</td>
                  <td style={{
                    "width": "10%"
                  }}> +7 bonus</td>
                </tr>
                <tr>
                  <td>64-68 </td>
                  <td>Great spell resistance (SR 23) </td>
                  <td style={{
                    "width": "10%"
                  }}>+7 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>69-71 </td>
                  <td> Exceptional arrow deflection</td>
                  <td style={{
                    "width": "10%"
                  }}>+8 bonus </td>
                </tr>
                <tr>
                  <td>72-75 </td>
                  <td> Great spell resistance (SR 25)</td>
                  <td style={{
                    "width": "10%"
                  }}>+8 bonus </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>76-78</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>Great
spell resistance (SR 27)</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>+9
bonus
                  </td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>79</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>Great reflection</td>
                  <td style={{
                    "verticalAlign": "top"
                  }}>+10 bonus
                  </td>
                </tr>
                <tr className="odd-row">
                  <td> 80-87
                  </td>
                  <td rowSpan="1" colSpan="2">Roll on nonepic magic
item Table: Shield Special Abilities, then roll again on this table. 
                  </td>
                </tr>
                <tr>
                  <td> 88-95
                  </td>
                  <td rowSpan="1" colSpan="2">Roll twice on nonepic
magic item Table: Shield Special Abilities. 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>96-100 </td>
                  <td rowSpan="1" colSpan="2">Roll twice again<sup>2</sup>
                  </td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="3">1<span style={{
                      "fontStyle": "italic"
                    }}> Add to enhancement bonus on Table: Epic
Armor and Shields to determine total market price. &nbsp;&nbsp;&nbsp; 
                    </span>2<span style={{
                      "fontStyle": "italic"
                    }}> If you
roll a special ability twice, only one counts. If you roll two versions
of the same special ability, use the better.</span>
                  </td>
                </tr>
              </tbody>
            </table></td>
          <td style={{
            "width": "6%"
          }}> 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "47px"
          }}><span style={{
              "fontWeight": "bold"
            }}><a id="table-epic-armors-and-shields"></a>Table: Epic Armors and Shields</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "15%"
                  }}>d%
                  </th>
                  <th style={{
                    "width": "35%"
                  }}>Enhancement Bonus
                  </th>
                  <th style={{
                    "width": "20%"
                  }}>Base Price</th>
                </tr>
                <tr className="odd-row">
                  <td>01-21</td>
                  <td>+11</td>
                  <td>+1,210,000 gp</td>
                </tr>
                <tr>
                  <td>22-39</td>
                  <td>+12
                  </td>
                  <td>+1,440,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>40-54</td>
                  <td>+13
                  </td>
                  <td>+1,690,000 gp</td>
                </tr>
                <tr>
                  <td>55-66</td>
                  <td>+14
                  </td>
                  <td>+1,960,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>67-76</td>
                  <td>+15
                  </td>
                  <td>+2,250,000 gp</td>
                </tr>
                <tr>
                  <td>77-84</td>
                  <td>+16
                  </td>
                  <td>+2,560,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>85-90</td>
                  <td>+17
                  </td>
                  <td>+2,890,000 gp</td>
                </tr>
                <tr>
                  <td>91-94</td>
                  <td>+18
                  </td>
                  <td>+3,240,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>95-97</td>
                  <td>+19
                  </td>
                  <td>+3,610,000 gp</td>
                </tr>
                <tr>
                  <td>98-99</td>
                  <td>+20
                  </td>
                  <td>+4,000,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>100</td>
                  <td>Roll again and add +10 to bonus<sup>1</sup></td>
                  <td><sup>2</sup></td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="3">1<span style={{
                      "fontStyle": "italic"
                    }}> This is cumulative if rolled multiple
times. &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; </span>
                    <p>{`2`}<span style={{
                        "fontStyle": "italic"
                      }}>{` For enhancement
bonuses higher than +20, the market price modifier is equal to the
square of the bonus x10,000 gp.`}</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <a id="table-random-armor-type"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Random Armor Type</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "20%"
                  }}>d%</th>
                  <th style={{
                    "width": "50%"
                  }}>Armor</th>
                  <th style={{
                    "width": "30%"
                  }}>Armor Cost<sup>1</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01</td>
                  <td>Padded</td>
                  <td>+155 gp</td>
                </tr>
                <tr>
                  <td>02</td>
                  <td>Leather</td>
                  <td>+160 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>03&ndash;12</td>
                  <td>Hide</td>
                  <td>+165 gp</td>
                </tr>
                <tr>
                  <td>13-27
                  </td>
                  <td>Studded leather</td>
                  <td>+175 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>28-42
                  </td>
                  <td>Chain shirts<sup>2</sup></td>
                  <td>+250 gp</td>
                </tr>
                <tr>
                  <td>43
                  </td>
                  <td>Scale mail<sup>2</sup></td>
                  <td>+200 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>44
                  </td>
                  <td>Chainmail<sup>2</sup></td>
                  <td>+300 gp</td>
                </tr>
                <tr>
                  <td>45-57
                  </td>
                  <td>Breastplate<sup>2</sup></td>
                  <td>+350 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>58
                  </td>
                  <td>Splint mail<sup>2</sup></td>
                  <td>+350 gp</td>
                </tr>
                <tr>
                  <td>59
                  </td>
                  <td>Banded mail<sup>2</sup></td>
                  <td>+400 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>60
                  </td>
                  <td>Half-plate<sup>2</sup></td>
                  <td>+750 gp</td>
                </tr>
                <tr>
                  <td className="last-row">61-100
                  </td>
                  <td className="last-row">Full plate<sup>2</sup></td>
                  <td className="last-row">+1,650 gp</td>
                </tr>
                <tr>
                  <td colSpan="3"><i>All magic armor is masterwork
armor (with an armor check penalty 1 lower than normal).
&nbsp;&nbsp;&nbsp; 
                    </i>1<i> Add to enhancement bonus on Table: Epic
Armor and Shields to
determine total market price. &nbsp;&nbsp;&nbsp; 
                    </i>2<i> Roll d% to determine material: 01-70 steel;
71-90 mithral; 91-99
adamantine; 100 other. Adjust price accordingly.
                    </i></td>
                </tr>
              </tbody>
            </table>
            <a id="table-armor-special-abilities"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Armor Special
Abilities
            </span>
            <table style={{
              "width": "500px"
            }} border="0" cellPadding="1" cellSpacing="0">
              <tbody>
                <tr>
                  <th style={{
                    "width": "12%"
                  }}>d% </th>
                  <th style={{
                    "width": "44%"
                  }}>Special Ability </th>
                  <th style={{
                    "width": "10%"
                  }}>Market Price Modifier<sup>1</sup>
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>01-06 </td>
                  <td>Great invulnerability (10/magic)
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>+4 bonus </td>
                </tr>
                <tr>
                  <td>07-11 </td>
                  <td>Great invulnerability (15/magic) </td>
                  <td style={{
                    "width": "10%"
                  }}>+5 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>12-19 </td>
                  <td>Negating </td>
                  <td style={{
                    "width": "10%"
                  }}>+5 bonus </td>
                </tr>
                <tr>
                  <td>20-26 </td>
                  <td>Acid warding </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>27-33 </td>
                  <td>Cold warding </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr>
                  <td>34-40 </td>
                  <td>Fire warding </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>41-44 </td>
                  <td>Great invulnerability (5/epic) </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr>
                  <td>45-50 </td>
                  <td>Great spell resistance (SR 21)&nbsp; </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>51-57 </td>
                  <td>Lightning warding </td>
                  <td style={{
                    "width": "10%"
                  }}> +6 bonus</td>
                </tr>
                <tr>
                  <td>58-64 </td>
                  <td>Sonic warding </td>
                  <td style={{
                    "width": "10%"
                  }}>+6 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>65-67 </td>
                  <td>Great invulnerability (10/epic) </td>
                  <td style={{
                    "width": "10%"
                  }}> +7 bonus</td>
                </tr>
                <tr>
                  <td>68-72 </td>
                  <td>Great spell resistance (SR
23)&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; 
                  </td>
                  <td style={{
                    "width": "10%"
                  }}>+7 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>73-76 </td>
                  <td>Great spell resistance (SR 25) </td>
                  <td style={{
                    "width": "10%"
                  }}>+8 bonus </td>
                </tr>
                <tr>
                  <td>77-79 </td>
                  <td>Great spell resistance (SR 27) </td>
                  <td style={{
                    "width": "10%"
                  }}>+9 bonus </td>
                </tr>
                <tr className="odd-row">
                  <td>80-87 </td>
                  <td rowSpan="1" colSpan="2">Roll on nonepic magic
item Table: Armor Special Abilities, then roll again on this table. 
                  </td>
                </tr>
                <tr>
                  <td>88-95 </td>
                  <td rowSpan="1" colSpan="2">Roll twice on nonepic
magic item Table: Armor Special Abilities. 
                  </td>
                </tr>
                <tr className="odd-row">
                  <td>96-100 </td>
                  <td rowSpan="1" colSpan="2">Roll twice again<sup>2</sup>
                  </td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="3">1<span style={{
                      "fontStyle": "italic"
                    }}> Add to enhancement bonus on Table: Epic
Armor and Shields to determine total market price. &nbsp;&nbsp;&nbsp; </span>
2<span style={{
                      "fontStyle": "italic"
                    }}> If a special ability is rolled
twice, only one counts. If two versions of the same special ability are
rolled, use the better.</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-armor-and-shield-special-ability-descriptions"></a><h5>EPIC ARMOR AND SHIELD SPECIAL ABILITY DESCRIPTIONS </h5>
Most magic armor and shields only have enhancement bonuses. Such items
can also have special abilities, such as those detailed below and
nonepic abilities. Armor or a shield with a special ability must have
at least a +1 enhancement bonus.
    <a id="acid-warding"></a><p><span style={{
        "fontWeight": "bold"
      }}>Acid Warding:</span> The
armor absorbs the first 50 points of acid damage per round that the
wearer would normally take (similar to the resist energy spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="cold-warding"></a><p><span style={{
        "fontWeight": "bold"
      }}>Cold Warding:</span> The
armor absorbs the first 50 points of cold damage per round that the
wearer would normally take (similar to the resist energy spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="exceptional-arrow-deflection"></a><p><span style={{
        "fontWeight": "bold"
      }}>Exceptional Arrow Deflection:</span>
This shield functions like a shield of arrow deflection except that it
can deflect any type of ranged attack (including spells that require a
ranged touch attack) as if it were an arrow. Any time the bearer would
normally be struck by a ranged attack, he or she can make a Reflex
saving throw (DC 20). If the ranged attack has an enhancement bonus (or
a spell level), the DC increases by that amount. If he or she succeeds,
the shield deflects the attack. The bearer must be aware of the attack
and not flat-footed. Attempting to deflect a ranged attack
doesn&rsquo;t
count as an action.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, shield, wall of force; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +8 bonus. </p>
    <a id="fire-warding"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fire Warding:</span> The
armor absorbs the first 50 points of fire damage per round that the
wearer would normally take (similar to the resist energy spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="great-invulnerability"></a><p><span style={{
        "fontWeight": "bold"
      }}>Great Invulnerability:</span>
This suit of armor or shield grants the wearer damage reduction. The
damage reduction can be 10/magic, 15/magic, 5/epic, or 10/epic,
depending on the armor.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 19th
(10/magic), 20th (15/magic), 21st (5/epic), 22nd (10/epic); <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, stoneskin, wish, or miracle; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>+4 bonus (10/magic),+5
bonus (15/magic),+6 bonus (5/epic),+7 bonus (10/epic). </p>
    <a id="great-reflection"></a><p><span style={{
        "fontWeight": "bold"
      }}>Great Reflection:</span> Any
time its bearer of this shield is targeted with a spell, it
automatically reflects the spell back at the caster (as the spell
turning spell). The wearer can lower or raise this effect as a free
action (thus allowing beneficial spells in as desired).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 25th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, spell turning; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +10 bonus. </p>
    <a id="great-spell-resistance"></a><p><span style={{
        "fontWeight": "bold"
      }}>Great Spell Resistance:</span>
This suit of armor or shield grants the wearer spell resistance. The
spell resistance can be 21, 23, 25, or 27, depending on the armor.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st (SR
21), 22nd (SR 23), 23rd (SR 25), 24th (SR 27); <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, spell resistance; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus (SR 21), +7
bonus (SR 23), +8 bonus (SR 25), +9 bonus (SR 27). </p>
    <a id="infinite-arrow-deflection"></a><p><span style={{
        "fontWeight": "bold"
      }}>Infinite Arrow Deflection:</span>
This shield functions like a shield of arrow deflection, though it can
deflect any number of projectiles or thrown weapons each round. Any
time the bearer would normally be struck by a ranged weapon, he or she
can make a Reflex saving throw (DC 20). If the ranged weapon has an
enhancement bonus, the DC increases by that amount. If he or she
succeeds, the shield deflects the weapon. The bearer must be aware of
the attack and not flat-footed. Attempting to deflect a ranged weapon
doesn&rsquo;t count as an action. Exceptional ranged weapons, such as
boulders hurled by giants or ranged spells, can&rsquo;t be deflected.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from arrows, shield;
      <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="lightning-warding"></a><p><span style={{
        "fontWeight": "bold"
      }}>Lightning Warding:</span> The
armor absorbs the first 50 points of lightning damage per round that
the wearer would normally take (similar to the resist energy spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="negating"></a><p><span style={{
        "fontWeight": "bold"
      }}>Negating:</span> Immediately
after the wearer of this armor is hit with a magic weapon, the armor
casts greater dispelling on the weapon. (In the case of projectile
weapons, the armor casts greater dispelling on the weapon that fired
the projectile if it is in range. If it is out of range, the armor does
nothing.) No weapon can be affected by the armor more than once per day
(regardless of the success of the dispel check).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, greater dispelling; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +5 bonus. </p>
    <a id="sonic-warding"></a><p><span style={{
        "fontWeight": "bold"
      }}>Sonic Warding:</span> The
armor absorbs the first 50 points of sonic damage per round that the
wearer would normally take (similar to the resist energy spell).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus.
    </p>
    <a id="specific-armors-and-shields"></a><h5>SPECIFIC ARMORS AND SHIELDS
    </h5>
    <a id="specific-armor-or-shield"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "500px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "15%"
          }}>d%</th>
          <th style={{
            "width": "35%"
          }}>Specific Armor or Shield</th>
          <th style={{
            "width": "20%"
          }}>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01-20</td>
          <td>Shapeshifter&rsquo;s armor
          </td>
          <td>400,165 gp</td>
        </tr>
        <tr>
          <td>21-40</td>
          <td>Warlord&rsquo;s breastplate</td>
          <td>416,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>41-57</td>
          <td>Dragonskin armor</td>
          <td>564,550 gp</td>
        </tr>
        <tr>
          <td>58-71</td>
          <td>Armor of the celestial battalion</td>
          <td>616,300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>72-82 
          </td>
          <td>Armor of the abyssal horde</td>
          <td>768,260 gp</td>
        </tr>
        <tr>
          <td>83-93</td>
          <td>Antimagic armor</td>
          <td>871,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>94-100</td>
          <td>Bulwark of the great dragon</td>
          <td>1,612,980 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="anitmagic-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Antimagic Armor:</span> This
+1 negating full plate armor of invulnerability is crafted of
adamantine (and thus has damage reduction 3/-). The armor provides a -5
penalty on dispel checks made against it or its wearer.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, greater dispelling, stoneskin,
wish or miracle; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
871,500 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
436,500 gp + 18,700 XP. </p>
    <a id="armor-of-the-abyssal-horde"></a><p><span style={{
        "fontWeight": "bold"
      }}>Armor of the Abyssal Horde:</span>
This+6 full plate armor&rsquo;s clawed gauntlets are effectively +4
keen
weapons (1d10/19-20) that afflict the target as if she had been struck
by an energy drain spell (Fortitude negates DC 23). The armor bestows
two negative levels on any nonevil creature wearing it. These negative
levels persist as long as the armor is worn and disappear when the
armor is removed. The negative levels never result in actual level
loss, but they cannot be overcome in any way (including restoration
spells) while the armor is worn.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, creator must be evil, energy
drain; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 768,260
gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 385,260
gp + 17,660 XP. </p>
    <a id="armor-of-the-celestial-battalion"></a><p><span style={{
        "fontWeight": "bold"
      }}>Armor of the Celestial
Battalion:</span> This+7 chainmail is so fine and light that it can be
worn under normal clothing without revealing its presence. It has a
maximum Dexterity bonus of +10, no armor check penalty, and an arcane
spell failure chance of 10%. It is considered light armor, and it
allows the wearer to fly at will (as the fly spell). Furthermore, the
wearer is at all times surrounded by a magic circle against evil effect
(as the spell) which, if dispelled, can be created again as a free
action.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, creator must be good, magic
circle against evil; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
616,300 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
308,300 gp + 16,160 XP. </p>
    <a id="bulwark-of-the-great-dragon"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bulwark of the Great Dragon:</span>
This +6 heavy shield bears the image of a dragon&rsquo;s head. Three
times
per day, the bearer of the shield can command it to belch forth a
breath weapon of the appropriate type. The range of this breath weapon
is 80 feet (if a line) or 40 feet (if a cone). Regardless of the type,
the breath weapon deals 20d6 points of damage. In addition, the shield
provides the bearer with resistance 50 to the energy type that matches
its breath weapon. To determine what type of dragon shield is found,
roll d% and consult the following table:
    </p>
    <a id="table-bulwark-of-the-dragon-type"></a><table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%</th>
          <th style={{
            "width": "10%"
          }}>Color</th>
          <th style={{
            "width": "20%"
          }}>Breath</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01-10</td>
          <td style={{
            "width": "10%"
          }}>Black
          </td>
          <td>Line of acid</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>11-20</td>
          <td style={{
            "width": "10%"
          }}>Blue</td>
          <td>Line of lightning</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>21-30</td>
          <td style={{
            "width": "10%"
          }}>Brass</td>
          <td>Line of fire</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>31-40</td>
          <td style={{
            "width": "10%"
          }}>Bronze</td>
          <td>Line of lightning</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>41-50</td>
          <td style={{
            "width": "10%"
          }}>Copper</td>
          <td>Line of acid</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>51-60</td>
          <td style={{
            "width": "10%"
          }}>Gold</td>
          <td>Cone of fire</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>61-70</td>
          <td style={{
            "width": "10%"
          }}>Green</td>
          <td>Cone of corrosive (acid) gas</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>71-80</td>
          <td style={{
            "width": "10%"
          }}>Red</td>
          <td>Cone of fire</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>81-90</td>
          <td style={{
            "width": "10%"
          }}>Silver</td>
          <td>Cone of cold</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>91-100</td>
          <td style={{
            "width": "10%"
          }}>White</td>
          <td>Cone of cold</td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 20th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 1,612,970 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 806,570 gp + 26,128
XP. </p>
    <a id="dragonskin-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Dragonskin Armor:</span>
This+5 full plate armor is crafted from the hide of a great wyrm
dragon. At the wearer&rsquo;s command, the armor sprouts enormous
dragon
wings, allowing the wearer to fly at a speed of 90 feet (clumsy) for a
total of 4 hours each day. The armor also grants immunity to a specific
type of energy, based on the color of dragon that supplied the armor.
Roll d% on the following table to determine the color and immunity.</p>
    <a id="table-dragonskin-armor-color-and-immunity"></a><table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%</th>
          <th style={{
            "width": "10%"
          }}>Color</th>
          <th style={{
            "width": "20%"
          }}>Immunity</th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01-10</td>
          <td style={{
            "width": "10%"
          }}>Black
          </td>
          <td>Acid</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>11-20</td>
          <td style={{
            "width": "10%"
          }}>Blue</td>
          <td>Lightning</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>21-30</td>
          <td style={{
            "width": "10%"
          }}>Brass</td>
          <td>Fire</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>31-40</td>
          <td style={{
            "width": "10%"
          }}>Bronze</td>
          <td>Lightning</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>41-50</td>
          <td style={{
            "width": "10%"
          }}>Copper</td>
          <td>Acid</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>51-60</td>
          <td style={{
            "width": "10%"
          }}>Gold</td>
          <td>Fire</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>61-70</td>
          <td style={{
            "width": "10%"
          }}>Green</td>
          <td>Acid</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>71-80</td>
          <td style={{
            "width": "10%"
          }}>Red</td>
          <td>Fire</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>81-90</td>
          <td style={{
            "width": "10%"
          }}>Silver</td>
          <td>Cold</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>91-100</td>
          <td style={{
            "width": "10%"
          }}>White</td>
          <td>Cold</td>
        </tr>
      </tbody>
    </table>
    <p>The wearer of the armor takes a -4 circumstance penalty on
Diplomacy checks with dragons, but gains a +4 circumstance bonus on
Intimidate checks against dragons.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 24th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from energy,
shapechange; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
564,550 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
283,250 gp + 15,629 XP. </p>
    <a id="shapeshifters-armor"></a><p><span style={{
        "fontWeight": "bold"
      }}>Shapeshifter&rsquo;s Armor:</span>
This suit of +6 hide armor grants its full Armor Class bonus regardless
of any form the wearer takes (with polymorph, shapechange, wild shape,
or similar abilities).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, shapechange or wild shape
ability; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
400,165 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
200,165 gp + 14,000 XP. </p>
    <a id="warlords-breastplate"></a><p><span style={{
        "fontWeight": "bold"
      }}>Warlord&rsquo;s Breastplate:</span>
This +6 mithral breastplate has an armor check penalty of -1, a maximum
Dexterity bonus of +5, and an arcane spell failure chance of 15%. It is
considered light armor and weighs 15 pounds. It grants the wearer a +4
enhancement bonus to Charisma and allows the wearer to attract and lead
a number of followers as if he or she had the Leadership feat (though
this power doesn&rsquo;t allow the wearer to attract a cohort). If the
wearer
already has the Leadership feat, this armor has no cumulative effect on
his or her followers.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, charm monster, sympathy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 416,200 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 210,200 gp + 14,120
XP.</p>
    <a id="epic-magic-weapons"></a><h3>WEAPONS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
Except when otherwise stated, epic magic weapons follow the rules for
nonepic magic weapons.&nbsp; There is no limit to an epic magic
weapon&rsquo;s enhancement bonus, to the market price modifier of an
epic
magic weapon special ability, or to the total of an epic magic
weapon&rsquo;s
enhancement bonus and market price modifier. 
    <a id="epic-weapon-base-price"></a><h5>EPIC WEAPON BASE PRICE </h5>
To find the base price of an epic magic weapon, roll on Table: Weapons.
Note that the +6 to +10 rows apply only to weapons that provide an
enhancement bonus of +6 to +10 or weapons with a single special ability
whose market price modifier is +6 to +10. Magic weapons with a total
effective bonus of +6 to +10 but that have an enhancement bonus of +5
or less and special abilities whose individual market price modifiers
are +5 or less use the table for nonepic magic weapons to determine
price. 
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Table:
Weapons
            </span>
            <a id="table-epic-weapon-market-price"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th>d%</th>
                  <th>Enhancement Bonus</th>
                  <th>Market Price<sup><sub>1</sub></sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01-03</td>
                  <td>+1
                  </td>
                  <td>+2,000 gp</td>
                </tr>
                <tr>
                  <td>04-07</td>
                  <td>+2
                  </td>
                  <td>+8,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>08-13</td>
                  <td>+3
                  </td>
                  <td>+18,000 gp</td>
                </tr>
                <tr>
                  <td>14-20</td>
                  <td>+4
                  </td>
                  <td>+50,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>21-28</td>
                  <td>+5
                  </td>
                  <td>+32,000 gp</td>
                </tr>
                <tr>
                  <td>29-36</td>
                  <td>+6
                  </td>
                  <td>+720,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>37-43</td>
                  <td>+7
                  </td>
                  <td>+980,000 gp</td>
                </tr>
                <tr>
                  <td>44-49</td>
                  <td>+8
                  </td>
                  <td>+1,280,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>50-53</td>
                  <td>+9
                  </td>
                  <td>+1,620,000 gp</td>
                </tr>
                <tr>
                  <td>54-56</td>
                  <td>+10
                  </td>
                  <td>+2,000,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>57-61</td>
                  <td>Specific weapon<sup><sub>2</sub></sup>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td className="last-row">62-80</td>
                  <td className="last-row" rowSpan="1" colSpan="2">Melee
weapon with special ability and roll again<sup><sub>3</sub></sup></td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }}>81-99</td>
                  <td style={{
                    "verticalAlign": "top",
                    "backgroundColor": "rgb(242, 228, 188)"
                  }} rowSpan="1" colSpan="2">Ranged weapon with special ability and roll
again<sup>3</sup></td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }}>100</td>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="2">Roll on Table: Epic Weapons</td>
                </tr>
                <tr>
                  <td style={{
                    "verticalAlign": "top"
                  }} rowSpan="1" colSpan="3">1<span style={{
                      "fontStyle": "italic"
                    }}> This price is for 50
arrows, crossbow bolts, or sling bullets. &nbsp;&nbsp;&nbsp; </span>
                    <p>{`2`}<span style={{
                        "fontStyle": "italic"
                      }}>{` See Table: Specific Weapons.
`}{` `}{` `}{` `}{` `}</span></p>
                    <p>{`3`}<span style={{
                        "fontStyle": "italic"
                      }}>{` See Table: Melee Weapon Special
Abilities for melee weapons or Table: Ranged Weapon Special Abilities
for ranged weapons.`}</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <a id="table-epic-weapons"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Epic Weapons
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th>d%</th>
                  <th>Enhancement Bonus</th>
                  <th>Market Price</th>
                </tr>
                <tr className="odd-row">
                  <td>01-21</td>
                  <td>+11
                  </td>
                  <td>+2,420,000 gp</td>
                </tr>
                <tr>
                  <td>22-39</td>
                  <td>+12
                  </td>
                  <td>+2,880,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>40-54</td>
                  <td>+13
                  </td>
                  <td>+3,380,000 gp</td>
                </tr>
                <tr>
                  <td>55-66</td>
                  <td>+14
                  </td>
                  <td>+3,920,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>67-76</td>
                  <td>+15
                  </td>
                  <td>+4,500,000 gp</td>
                </tr>
                <tr>
                  <td>77-84</td>
                  <td>+16
                  </td>
                  <td>+5,120,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>85-90</td>
                  <td>+17
                  </td>
                  <td>+5,780,000 gp</td>
                </tr>
                <tr>
                  <td>91-94</td>
                  <td>+18
                  </td>
                  <td>+6,480,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>95-97</td>
                  <td>+19
                  </td>
                  <td>+7,220,000 gp</td>
                </tr>
                <tr>
                  <td>98-99</td>
                  <td>+20
                  </td>
                  <td>+8,000,000 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>100</td>
                  <td>Roll again and add +10 to bonus<sup>1</sup></td>
                  <td><sup>2</sup>
                  </td>
                </tr>
                <tr>
                  <td className="last-row" rowSpan="1" colSpan="3">1<span style={{
                      "fontStyle": "italic"
                    }}>This is cumulative if rolled multiple
times. &nbsp;&nbsp;&nbsp; </span>
                    <p>{`2`}<span style={{
                        "fontStyle": "italic"
                      }}>{` For enhancement bonuses higher than
+20, the market price modifier is equal to the square of the bonus
x20,000 gp.`}</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <a id="table-ranged-weapons"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Ranged Weapons</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "20%"
                  }}>d%</th>
                  <th style={{
                    "width": "50%"
                  }}>Weapon</th>
                  <th style={{
                    "width": "30%"
                  }}>Weapon Cost<sup>1</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01&ndash;10</td>
                  <td>Ammunition (roll again):</td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>01&ndash;50 Arrows (50)</td>
                  <td>+350 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>
                  </td>
                  <td>51&ndash;80 Bolts, crossbow (50)</td>
                  <td>+350 gp</td>
                </tr>
                <tr>
                  <td>
                  </td>
                  <td>81&ndash;100 Bullets, sling (50)</td>
                  <td>+350 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>11&ndash;15</td>
                  <td>Axe, throwing</td>
                  <td>+308 gp</td>
                </tr>
                <tr>
                  <td>16&ndash;25</td>
                  <td>Crossbow, heavy</td>
                  <td>+350 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>26&ndash;35</td>
                  <td>Crossbow, light</td>
                  <td>+335 gp</td>
                </tr>
                <tr>
                  <td>36&ndash;39</td>
                  <td>Dart</td>
                  <td>+300 gp 5 sp</td>
                </tr>
                <tr className="odd-row">
                  <td>40&ndash;41</td>
                  <td>Javelin</td>
                  <td>+301 gp</td>
                </tr>
                <tr>
                  <td>42&ndash;46</td>
                  <td>Shortbow</td>
                  <td>+330 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>47&ndash;51</td>
                  <td>Shortbow, composite (+0 Str bonus)</td>
                  <td>+375 gp</td>
                </tr>
                <tr>
                  <td>52&ndash;56</td>
                  <td>Shortbow, composite (+1 Str bonus)</td>
                  <td>+450 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>57&ndash;61</td>
                  <td>Shortbow, composite (+2 Str bonus)</td>
                  <td>+525 gp</td>
                </tr>
                <tr>
                  <td>62&ndash;65</td>
                  <td>Sling</td>
                  <td>+300 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>66&ndash;75</td>
                  <td>Longbow</td>
                  <td>+375 gp</td>
                </tr>
                <tr>
                  <td>76&ndash;80</td>
                  <td>Longbow, composite</td>
                  <td>+400 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>81&ndash;85</td>
                  <td>Longbow, composite (+1 Str bonus)</td>
                  <td>+500 gp</td>
                </tr>
                <tr>
                  <td>86&ndash;90</td>
                  <td>Longbow, composite (+2 Str bonus)</td>
                  <td>+600 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>91&ndash;95</td>
                  <td>Longbow, composite (+3 Str bonus)</td>
                  <td>+700 gp</td>
                </tr>
                <tr>
                  <td className="last-row">96&ndash;100</td>
                  <td className="last-row">Longbow, composite (+4 Str bonus)</td>
                  <td className="last-row">+800 gp</td>
                </tr>
                <tr>
                  <td colSpan="3"><i>All magic weapons are masterwork
weapons.</i>
1 <i>Add to enhancement bonus on Table:
Weapons to determine total market price.</i></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{
            "width": "6%"
          }}> 
          </td>
          <td style={{
            "verticalAlign": "top",
            "width": "47px"
          }}><a id="table-weapon-type-determination"></a><span style={{
              "fontWeight": "bold"
            }}>Table:
Weapon Type Determination
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "20%"
                  }}>d%</th>
                  <th style={{
                    "width": "80%"
                  }}>Weapon Type</th>
                </tr>
                <tr className="odd-row">
                  <td>01&ndash;70</td>
                  <td>Roll on Table: Common Melee Weapon
                  </td>
                </tr>
                <tr>
                  <td>71&ndash;80</td>
                  <td>Roll on Table: Uncommon Melee Weapons</td>
                </tr>
                <tr className="odd-row">
                  <td>81&ndash;100</td>
                  <td>Roll on Table: Ranged Weapons</td>
                </tr>
              </tbody>
            </table>
            <a id="table-common-melee-weapons"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Common Melee Weapons</span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "20%"
                  }}>d%</th>
                  <th style={{
                    "width": "50%"
                  }}>Weapon<sup>1</sup></th>
                  <th style={{
                    "width": "30%"
                  }}>Weapon Cost<sup>2</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01&ndash;04</td>
                  <td>Dagger</td>
                  <td>+302 gp</td>
                </tr>
                <tr>
                  <td>05&ndash;14</td>
                  <td>Greataxe</td>
                  <td>+320 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>15&ndash;24</td>
                  <td>Greatsword</td>
                  <td>+350 gp</td>
                </tr>
                <tr>
                  <td>25&ndash;28</td>
                  <td>Kama</td>
                  <td>+302 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>29&ndash;41</td>
                  <td>Longsword</td>
                  <td>+315 gp</td>
                </tr>
                <tr>
                  <td>42&ndash;45</td>
                  <td>Mace, light</td>
                  <td>+305 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>46&ndash;50</td>
                  <td>Mace, heavy</td>
                  <td>+312 gp</td>
                </tr>
                <tr>
                  <td>51&ndash;54</td>
                  <td>Nunchaku</td>
                  <td>+302 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>55&ndash;57</td>
                  <td>Quarterstaff<sup>3</sup></td>
                  <td>+600 gp</td>
                </tr>
                <tr>
                  <td>58&ndash;61</td>
                  <td>Rapier</td>
                  <td>+320 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>62&ndash;66</td>
                  <td>Scimitar</td>
                  <td>+315 gp</td>
                </tr>
                <tr>
                  <td>67&ndash;70</td>
                  <td>Shortspear</td>
                  <td>+302 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>71&ndash;74</td>
                  <td>Siangham</td>
                  <td>+303 gp</td>
                </tr>
                <tr>
                  <td>75&ndash;84</td>
                  <td>Sword, bastard</td>
                  <td>+335 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>85&ndash;89</td>
                  <td>Sword, short</td>
                  <td>+310 gp</td>
                </tr>
                <tr>
                  <td className="last-row">90&ndash;100</td>
                  <td className="last-row">Waraxe, dwarven</td>
                  <td className="last-row">+330 gp</td>
                </tr>
                <tr>
                  <td colSpan="3"><i>All magic weapons are masterwork
weapons. &nbsp;&nbsp;&nbsp; 
                    </i>1<i> For weapons normally made of steel, roll d%
to determine
material: 01-85 steel; 86-99 adamantine; 100 other. Adjust price
accordingly. &nbsp;&nbsp;&nbsp; 
                    </i>2<i> Add to enhancement bonus on Table: Weapons
to determine total market price. &nbsp;&nbsp;&nbsp; 
                    </i>3<i> Masterwork double weapons incur double the
masterwork
cost to account for each head (+300 gp masterwork cost per head for a
total of +600 gp). Double weapons have separate bonuses for their
different heads. If randomly determined, the second head of a double
weapon has the same enhancement bonus as the main head (01-50 on d%),
doubling the cost of the bonus, or its enhancement bonus is one less
(51-100 on d%) and it has no special abilities.
                    </i></td>
                </tr>
              </tbody>
            </table>
            <a id="table-uncommon-melee-weapons"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Uncommon Melee
Weapons
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr>
                  <th style={{
                    "width": "20%"
                  }}>d%</th>
                  <th style={{
                    "width": "50%"
                  }}>Weapon<sup>1</sup></th>
                  <th style={{
                    "width": "30%"
                  }}>Weapon Cost<sup>2</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01&ndash;03</td>
                  <td>Axe, orc double<sup>3</sup></td>
                  <td>+660 gp</td>
                </tr>
                <tr>
                  <td>04&ndash;07</td>
                  <td>Battleaxe</td>
                  <td>+310 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>08&ndash;10</td>
                  <td>Chain, spiked</td>
                  <td>+325 gp</td>
                </tr>
                <tr>
                  <td>11&ndash;12</td>
                  <td>Club</td>
                  <td>+300 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>13&ndash;16</td>
                  <td>Crossbow, hand</td>
                  <td>+400 gp</td>
                </tr>
                <tr>
                  <td>17&ndash;19</td>
                  <td>Crossbow, repeating</td>
                  <td>+550 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>20&ndash;21</td>
                  <td>Dagger, punching</td>
                  <td>+302 gp</td>
                </tr>
                <tr>
                  <td>22&ndash;23</td>
                  <td>Falchion</td>
                  <td>+375 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>24&ndash;26</td>
                  <td>Flail, dire<sup>3</sup></td>
                  <td>+690 gp</td>
                </tr>
                <tr>
                  <td>27&ndash;31</td>
                  <td>Flail, heavy</td>
                  <td>+315 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>32&ndash;35</td>
                  <td>Flail, light</td>
                  <td>+308 gp</td>
                </tr>
                <tr>
                  <td>36&ndash;37</td>
                  <td>Gauntlet</td>
                  <td>+302 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>38&ndash;39</td>
                  <td>Gauntlet, spiked</td>
                  <td>+305 gp</td>
                </tr>
                <tr>
                  <td>40&ndash;41</td>
                  <td>Glaive</td>
                  <td>+308 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>42&ndash;43</td>
                  <td>Greatclub</td>
                  <td>+305 gp</td>
                </tr>
                <tr>
                  <td>44&ndash;45</td>
                  <td>Guisarme</td>
                  <td>+309 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>46&ndash;48</td>
                  <td>Halberd</td>
                  <td>+310 gp</td>
                </tr>
                <tr>
                  <td>49&ndash;51</td>
                  <td>Halfspear</td>
                  <td>+301 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>52&ndash;54</td>
                  <td>Hammer, gnome hooked<sup>3</sup></td>
                  <td>+620 gp</td>
                </tr>
                <tr>
                  <td>55&ndash;56</td>
                  <td>Hammer, light</td>
                  <td>+301 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>57&ndash;58</td>
                  <td>Handaxe</td>
                  <td>+306 gp</td>
                </tr>
                <tr>
                  <td>59&ndash;61</td>
                  <td>Kukri</td>
                  <td>+308 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>62&ndash;64</td>
                  <td>Lance</td>
                  <td>+310 gp</td>
                </tr>
                <tr>
                  <td>65&ndash;67</td>
                  <td>Longspear</td>
                  <td>+305 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>68&ndash;70</td>
                  <td>Morningstar</td>
                  <td>+308 gp</td>
                </tr>
                <tr>
                  <td>71&ndash;72</td>
                  <td>Net</td>
                  <td>+320 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>73&ndash;74</td>
                  <td>Pick, heavy</td>
                  <td>+308 gp</td>
                </tr>
                <tr>
                  <td>75&ndash;76</td>
                  <td>Pick, light</td>
                  <td>+304 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>77&ndash;78</td>
                  <td>Ranseur</td>
                  <td>+310 gp</td>
                </tr>
                <tr>
                  <td>79&ndash;80</td>
                  <td>Sap</td>
                  <td>+301 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>81&ndash;82</td>
                  <td>Scythe</td>
                  <td>+318 gp</td>
                </tr>
                <tr>
                  <td>83&ndash;84</td>
                  <td>Shuriken</td>
                  <td>+301 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>85&ndash;86</td>
                  <td>Sickle</td>
                  <td>+306 gp</td>
                </tr>
                <tr>
                  <td>87&ndash;89</td>
                  <td>Sword, two-bladed<sup>3</sup></td>
                  <td>+700 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>90&ndash;91</td>
                  <td>Trident</td>
                  <td>+315 gp</td>
                </tr>
                <tr>
                  <td>92&ndash;94</td>
                  <td>Urgrosh, dwarven<sup>3</sup></td>
                  <td>+650 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>95&ndash;97</td>
                  <td>Warhammer</td>
                  <td>+312 gp</td>
                </tr>
                <tr>
                  <td className="last-row">98&ndash;100</td>
                  <td className="last-row">Whip</td>
                  <td className="last-row">+301 gp</td>
                </tr>
                <tr>
                  <td colSpan="3"><i>All magic weapons are masterwork
weapons. &nbsp;&nbsp;&nbsp; 
                    </i>1<i> For weapons normally made of steel, roll d%
to determine
material: 01-85 steel; 86-99 adamantine; 100 other. Adjust price
accordingly. &nbsp;&nbsp;&nbsp; 
                    </i>2<i> Add to enhancement bonus on Table: Weapons
to determine total market price. &nbsp;&nbsp;&nbsp; 
                    </i>3<i> Masterwork double weapons incur double the
masterwork
cost to account for each head (+300 gp masterwork cost per head for a
total of +600 gp). Double weapons have separate bonuses for their
different heads. If randomly determined, the second head of a double
weapon has the same enhancement bonus as the main head (01-50 on d%),
doubling the cost of the bonus, or its enhancement bonus is one less
(51-100) and it has no special abilities.
                    </i></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "47%",
            "verticalAlign": "top"
          }}><a id="table-melee-weapon-special-abilities"></a>
            <span style={{
              "fontWeight": "bold"
            }}>Table:
Melee Weapon Special
Abilities
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Special Ability</th>
                  <th style={{
                    "width": "20%"
                  }}>Market Price Modifier<sup>1</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01-08</td>
                  <td>Acidic blast
                  </td>
                  <td>+6 bonus</td>
                </tr>
                <tr>
                  <td>09-16</td>
                  <td>Fiery blast 
                  </td>
                  <td>+6 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>17-24</td>
                  <td>Icy blast</td>
                  <td>+6 bonus</td>
                </tr>
                <tr>
                  <td>25-32</td>
                  <td>Lightning blast</td>
                  <td>+6 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>33-40</td>
                  <td>Mighty disruption</td>
                  <td>+6 bonus</td>
                </tr>
                <tr>
                  <td>41-48</td>
                  <td>Sonic blast</td>
                  <td>+6 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>49-56</td>
                  <td>Dread</td>
                  <td>+7 bonus</td>
                </tr>
                <tr>
                  <td>57-61</td>
                  <td>Anarchic power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>62-66</td>
                  <td>Everdancing</td>
                  <td>+8 bonus</td>
                </tr>
                <tr>
                  <td>67-71</td>
                  <td>Holy power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>72-76</td>
                  <td>Axiomatic power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr>
                  <td>77-81</td>
                  <td>Unholy power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>82-89</td>
                  <td rowSpan="1" colSpan="2">Roll on nonepic magic
item
Table: Melee Weapon Special Abilities, then roll again on this table.</td>
                </tr>
                <tr>
                  <td>90-97</td>
                  <td rowSpan="1" colSpan="2">Roll twice on nonepic
magic
item Table: Melee Weapon Special Abilities.</td>
                </tr>
                <tr className="odd-row">
                  <td>98-100</td>
                  <td rowSpan="1" colSpan="2">Roll twice again<sup>2</sup></td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="3">1 <span style={{
                      "fontStyle": "italic"
                    }}>Add to enhancement bonus on Table: Epic
Weapons to determine total market price. &nbsp;&nbsp;&nbsp; </span>
2<span style={{
                      "fontStyle": "italic"
                    }}> If a special ability is rolled
twice, only one counts. If two versions of the same special ability are
rolled, use the better. &nbsp;&nbsp;&nbsp; </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <a id="table-specific-weapons"></a><span style={{
              "fontWeight": "bold"
            }}>Table: Specific Weapons
            </span>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Specific Weapon
                  </th>
                  <th style={{
                    "width": "20%"
                  }}>Market Price
                  </th>
                </tr>
                <tr className="odd-row">
                  <td>01-18</td>
                  <td>Stormbrand
                  </td>
                  <td>235,350 gp</td>
                </tr>
                <tr>
                  <td>19-33</td>
                  <td>Quarterstaff of alacrity</td>
                  <td>462,600 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>34-48</td>
                  <td>Souldrinker</td>
                  <td>478,335 gp</td>
                </tr>
                <tr>
                  <td>49-60 
                  </td>
                  <td>Backstabber</td>
                  <td>770,310 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>61-68</td>
                  <td>Mace of ruin</td>
                  <td>1,000,312 gp</td>
                </tr>
                <tr>
                  <td>69-72</td>
                  <td>Gripsoul</td>
                  <td>1,856,500 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>73-78</td>
                  <td>Elven greatbow</td>
                  <td>2,900,400 gp</td>
                </tr>
                <tr>
                  <td>79-64</td>
                  <td> Finaldeath</td>
                  <td>3,580,308 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>85-90</td>
                  <td> Chaosbringer</td>
                  <td>4,025,350 gp</td>
                </tr>
                <tr>
                  <td>91-94</td>
                  <td>Holy devastator</td>
                  <td>4,620,315 gp</td>
                </tr>
                <tr className="odd-row">
                  <td>95-98</td>
                  <td>Unholy despoiler</td>
                  <td>4,620,315 gp</td>
                </tr>
                <tr>
                  <td>99-100</td>
                  <td>Everwhirling chain</td>
                  <td>5,220,325 gp</td>
                </tr>
              </tbody>
            </table>
          </td>
          <th style={{
            "width": "6%"
          }}> 
          </th>
          <td style={{
            "verticalAlign": "top",
            "width": "47px"
          }}><a id="table-ranged-weapon-special-abilities"></a><b>Table: Ranged Weapon Special Abilities
            </b>
            <table border="0" cellPadding="1" cellSpacing="0" style={{
              "width": "500px"
            }}>
              <tbody>
                <tr valign="bottom">
                  <th style={{
                    "width": "15%"
                  }}>d%</th>
                  <th style={{
                    "width": "35%"
                  }}>Special Ability</th>
                  <th style={{
                    "width": "20%"
                  }}>Market Price Modifier<sup>1</sup></th>
                </tr>
                <tr className="odd-row">
                  <td>01-08</td>
                  <td>Acidic blast
                  </td>
                  <td>+6 bonus</td>
                </tr>
                <tr>
                  <td>09-16</td>
                  <td>Fiery blast 
                  </td>
                  <td>+6 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>17-24</td>
                  <td>Icy blast</td>
                  <td>+6 bonus</td>
                </tr>
                <tr>
                  <td>25-32</td>
                  <td>Lightning blast</td>
                  <td>+6 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>33-40</td>
                  <td>Mighty disruption</td>
                  <td>+6 bonus</td>
                </tr>
                <tr>
                  <td>41-48</td>
                  <td>Sonic blast</td>
                  <td>+6 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>49-56</td>
                  <td>Dread</td>
                  <td>+7 bonus</td>
                </tr>
                <tr>
                  <td>57-61</td>
                  <td>Anarchic power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>62-66</td>
                  <td>Everdancing</td>
                  <td>+8 bonus</td>
                </tr>
                <tr>
                  <td>67-71</td>
                  <td>Holy power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>72-76</td>
                  <td>Axiomatic power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr>
                  <td>77-81</td>
                  <td>Unholy power</td>
                  <td>+8 bonus</td>
                </tr>
                <tr className="odd-row">
                  <td>82-89</td>
                  <td rowSpan="1" colSpan="2">Roll on nonepic magic
item
Table: Melee Weapon Special Abilities, then roll again on this table.</td>
                </tr>
                <tr>
                  <td>90-97</td>
                  <td rowSpan="1" colSpan="2">Roll twice on nonepic
magic
item Table: Melee Weapon Special Abilities.</td>
                </tr>
                <tr className="odd-row">
                  <td>98-100</td>
                  <td rowSpan="1" colSpan="2">Roll twice again<sup>2</sup></td>
                </tr>
                <tr>
                  <td rowSpan="1" colSpan="3">1 <span style={{
                      "fontStyle": "italic"
                    }}>Add to enhancement bonus on Table: Epic
Weapons to determine total market price. &nbsp;&nbsp;&nbsp; </span>
2<span style={{
                      "fontStyle": "italic"
                    }}> If a special ability is rolled
twice, only one counts. If two versions of the same special ability are
rolled, use the better. &nbsp;&nbsp;&nbsp; </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-weapon-special-ability-descriptions"></a><h6>EPIC WEAPON SPECIAL ABILITY DESCRIPTIONS </h6>
Most magic weapons only have enhancement bonuses. They can also have
special abilities, such as those detailed below and nonepic abilities.
A weapon with a special ability must have at least a +1 enhancement
bonus. 
    <a id="acidic-blast"></a><p><span style={{
        "fontWeight": "bold"
      }}>Acidic Blast:</span> On
command, an acidic blast weapon drips acid (though this deals no damage
to the wielder). On any hit, this acid splashes the creature struck,
dealing +3d6 points of bonus acid damage. On a successful critical hit
it instead deals +6d6 points of acid damage (or +9d6 if the critical
multiplier is x3, or +12d6 if the critical multiplier is x4). Bows,
crossbows, and slings with this special ability bestow the bonus acid
damage upon their ammunition.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, acid fog; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="anarchic-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Anarchic Power:</span> This
weapon is chaos-aligned and thus bypasses the corresponding damage
reduction. When a weapon of anarchic power strikes a lawful target,
this power deals +3d6 points of bonus chaotic damage to the target, and
the target gains one negative level (Fortitude DC 23 to remove 24 hours
later). On a successful critical hit it instead deals +6d6 points of
chaotic damage and bestows two negative levels (or +9d6 and three
negative levels if the critical multiplier is x3, or +12d6 and four
negative levels if the critical multiplier is x4). The weapon bestows
three negative levels on any lawful creature attempting to wield it.
These negative levels remain as long as the weapon is in hand and
disappear when the weapon is no longer wielded. These negative levels
never result in actual level loss, but they cannot be overcome in any
way (including restoration spells) while the weapon is wielded. Bows,
crossbows, and slings with this special ability bestow the anarchic
power upon their ammunition. This special ability does not stack with
the nonepic anarchic special ability.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, word of chaos; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +8 bonus. </p>
    <a id="axiomatic-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Axiomatic Power:</span> This
weapon is lawful-aligned and thus bypasses the corresponding damager
reduction. When a weapon of axiomatic power strikes a chaotic target,
this power erupts forth and deals +3d6 points of bonus lawful damage to
the target, and the target gains one negative level (Fortitude DC 23 to
remove 24 hours later). On a successful critical hit it instead deals
+6d6 points of lawful damage and bestows two negative levels (or +9d6
and three negative levels if the critical multiplier is x3, or +12d6
and four negative levels if the critical multiplier is x4). The weapon
bestows three negative levels on any chaotic creature attempting to
wield it. These negative levels remain as long as the weapon is in hand
and disappear when the weapon is no longer wielded. These negative
levels never result in actual level loss, but they cannot be overcome
in any way (including restoration spells) while the weapon is wielded.
Bows, crossbows, and slings with this special ability bestow the lawful
power upon their ammunition. This special ability does not stack with
the nonepic axiomatic special ability.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, dictum; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +8 bonus. </p>
    <a id="distant-shot"></a><p><span style={{
        "fontWeight": "bold"
      }}>Distant Shot:</span> A
distant shot weapon can be used against any target within line of sight
at no penalty for range.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, discern location; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="dread"></a><p><span style={{
        "fontWeight": "bold"
      }}>Dread:</span> A dread weapon
excels at attacking one type of creature. Against its designated foe,
its effective enhancement bonus is +4 better than its normal
enhancement bonus. Further, it deals +4d6 points of bonus damage
against the foe, and if it scores a successful critical hit against the
foe, that creature must make a Fortitude save (DC 27) or be destroyed
instantly and turned to dust. (This even affects creatures immune to
critical hits or death magic.) To randomly determine a dread
weapon&rsquo;s
designated foe, roll on the following table.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 22nd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, summon monster IX; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +7 bonus.
    </p>
    <a id="table-dread-weapon-designated-foe"></a><table style={{
      "width": "500px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "10%"
          }}>d%</th>
          <th style={{
            "width": "40%"
          }}>Designated Foe</th>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold",
            "width": "10px"
          }}>d%</td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Designated
Foe</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>01&ndash;05</td>
          <td style={{
            "width": "40%"
          }}>Aberrations</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>58&ndash;60</td>
          <td style={{
            "verticalAlign": "top"
          }}>Humanoids, orc</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>06&ndash;09</td>
          <td style={{
            "width": "40%"
          }}>Animals</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>61&ndash;65</td>
          <td style={{
            "verticalAlign": "top"
          }}>Magical beasts</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>10&ndash;16</td>
          <td style={{
            "width": "40%"
          }}>Constructs</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>66&ndash;70</td>
          <td style={{
            "verticalAlign": "top"
          }}>Monstrous humanoids</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>17&ndash;22</td>
          <td style={{
            "width": "40%"
          }}>Dragons</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>71&ndash;72</td>
          <td style={{
            "verticalAlign": "top"
          }}>Oozes</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>23&ndash;27</td>
          <td style={{
            "width": "40%"
          }}>Elementals</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>73
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, air</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>28&ndash;32</td>
          <td style={{
            "width": "40%"
          }}>Fey</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>74-76
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, chaotic</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>33&ndash;39</td>
          <td style={{
            "width": "40%"
          }}>Giants</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>77
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, earth</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>40</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, aquatic</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>78-80
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, evil</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>41&ndash;42</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, dwarf</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>81
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, fire</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>43&ndash;44</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, elf</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>82-84
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, good</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>45</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, gnoll</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>85-87
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, lawful</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>46</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, gnome</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>88
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Outsiders, water</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>47&ndash;49</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, goblinoid</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>89-90
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Plants</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>50</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, halfling</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>91-98
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Undead</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "10%"
          }}>51&ndash;54</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, human</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>99-100
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Vermin</td>
        </tr>
        <tr>
          <td style={{
            "width": "10%"
          }}>55&ndash;57</td>
          <td style={{
            "width": "40%"
          }}>Humanoids, reptilian</td>
          <td style={{
            "verticalAlign": "top",
            "width": "10px"
          }}>
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="everdancing"></a><p><span style={{
        "fontWeight": "bold"
      }}>Everdancing:</span> An
everdancing weapon is much like a dancing weapon, though it can be
loosed with a free action and will fight as long as desired. It can
move up to 60 feet away from its owner. Its owner can instruct it to
move to a different target as a move-equivalent action. If its owner is
rendered unconscious or otherwise unable to direct it, it will fight
the same opponent as long as that opponent is conscious and within
range. The owner of an everdancing weapon can grasp it again as a free
action (assuming it is within reach).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, animate objects; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +8 bonus. </p>
    <a id="fiery-blast"></a><p><span style={{
        "fontWeight": "bold"
      }}>Fiery Blast:</span> On
command, a fiery blast weapon is sheathed in fire (though this deals no
damage to the wielder). On any hit, this fire engulfs the creature
struck, dealing +3d6 points of bonus fire damage. On a successful
critical hit it instead deals +6d6 points of fire damage (or +9d6 if
the critical multiplier is x3, or +12d6 if the critical multiplier is
x4). Bows, crossbows, and slings with this special ability bestow the
bonus fire damage upon their ammunition.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, fireball; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="holy-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Holy Power:</span> This
weapon is good-aligned and thus bypasses the corresponding damager
reduction. When a weapon of holy power strikes an evil target, this
power erupts forth and deals +3d6 points of bonus holy (good) damage to
the target, and the target gains one negative level (Fortitude DC 23 to
remove 24 hours later). On a successful critical hit it instead deals
+6d6 points of holy (good) damage and bestows two negative levels (or
+9d6 and three negative levels if the critical multiplier is x3, or
+12d6 and four negative levels if the critical multiplier is x4). The
weapon bestows three negative levels on any evil creature attempting to
wield it. These negative levels remain as long as the weapon is in hand
and disappear when the weapon is no longer wielded. These negative
levels never result in actual level loss, but they cannot be overcome
in any way (including restoration spells) while the weapon is wielded.
Bows, crossbows, and slings with this special ability bestow the holy
power upon their ammunition. This special ability does not stack with
the nonepic holy special ability.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, holy word; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +8 bonus. </p>
    <a id="icy-blast"></a><p><span style={{
        "fontWeight": "bold"
      }}>Icy Blast:</span> On command,
an icy blast weapon is sheathed in icy cold (though this deals no
damage to the wielder). On any hit, this cold washes over the creature
struck, dealing&nbsp;&nbsp;&nbsp; +3d6 points of bonus cold damage. On
a successful critical hit it instead deals +6d6 points of cold damage
(or +9d6 if the critical multiplier is x3, or +12d6 if the critical
multiplier is x4). Bows, crossbows, and slings with this special
ability bestow the bonus cold damage upon their ammunition.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, cone of cold; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="lightning-blast"></a><p><span style={{
        "fontWeight": "bold"
      }}>Lightning Blast:</span> On
command, a lightning blast weapon crackles with electrical energy
(though this deals no damage to the wielder). On any hit, lightning
coruscates around the creature struck, dealing&nbsp;&nbsp;&nbsp;
&nbsp;+3d6 points of bonus electricity damage. On a successful critical
hit it instead deals +6d6 points of electricity damage (or +9d6 if the
critical multiplier is x3, or +12d6 if the critical multiplier is x4).
Bows, crossbows, and slings with this special ability bestow the bonus
electricity damage upon their ammunition.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, lightning bolt; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="mighty-disruption"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mighty Disruption:</span> Any
undead creature struck in combat must succeed at a Fortitude save (DC
21) or be destroyed. A weapon of mighty disruption must be a
bludgeoning weapon. (If this property is rolled for a piercing or
slashing weapon, reroll.)
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, true resurrection; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="sonic-blast"></a><p><span style={{
        "fontWeight": "bold"
      }}>Sonic Blast:</span> On
command, a sonic blast weapon emits a low thrumming hum (though this
deals no damage to the wielder). On any hit, this becomes a thunderous
roar that deals +3d6 points of bonus sonic damage to the creature
struck. On a successful critical hit it instead deals +6d6 points of
sonic damage (or +9d6 if the critical multiplier is x3, or +12d6 if the
critical multiplier is x4). Bows, crossbows, and slings with this
special ability bestow the bonus sonic damage upon their ammunition.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, shout; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="triple-throw"></a><p><span style={{
        "fontWeight": "bold"
      }}>Triple-Throw:</span> This
special ability can only be placed on a weapon that can be thrown. (If
this property is rolled for a weapon that cannot be thrown, reroll.) A
triple-throw weapon creates two duplicates of itself when thrown. Both
the original and the duplicate weapons attack separately (at the same
attack bonus). Regardless of the success of any of the attacks, the
duplicates immediately disappear after the attack is completed. Any
bonuses on damage due to accuracy or precision (including those from
sneak attacks, the Precise Shot feat, or the ranger&rsquo;s favored
enemy
bonus) apply only to the original weapon&rsquo;s damage, not to the
duplicates.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, shades; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="unerring-accuracy"></a><p><span style={{
        "fontWeight": "bold"
      }}>Unerring Accuracy:</span>
Ranged attacks made with this weapon negate the AC bonus granted by any
cover short of total cover.&nbsp; The weapon&rsquo;s ranged attacks
also
ignore any miss chance from concealment (including total concealment,
but the must still aim his or her attacks at the correct square).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, true seeing; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +6 bonus. </p>
    <a id="unholy-power"></a><p><span style={{
        "fontWeight": "bold"
      }}>Unholy Power:</span> This
weapon is evil-aligned and this bypasses the corresponding damage
reduction. When a weapon of unholy power strikes a good target, this
power erupts forth and deals +3d6 points of bonus unholy (evil) damage
to the target, and the target gains one negative level (Fortitude DC 23
to remove 24 hours later). On a successful critical hit it instead
deals +6d6 points of unholy (evil) damage and bestows two negative
levels (or +9d6 and three negative levels if the critical multiplier is
x3, or +12d6 and four negative levels if the critical multiplier is
x4). The weapon bestows three negative levels on any good creature
attempting to wield it. These negative levels remain as long as the
weapon is in hand and disappear when the weapon is no longer wielded.
These negative levels never result in actual level loss, but they
cannot be overcome in any way (including restoration spells) while the
weapon is wielded. Bows, crossbows, and slings with this special
ability bestow the unholy power upon their ammunition. This special
ability does not stack with the nonepic unholy special ability.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, unholy word; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> +8 bonus.
    </p>
    <a id="specific-weapons"></a><h6>SPECIFIC WEAPONS</h6>
    <a id="backstabber"></a><p><span style={{
        "fontWeight": "bold"
      }}>Backstabber:</span> This +6
short sword adds +2d6 to the wielder&rsquo;s sneak attack damage. If
the
wielder does not have the sneak attack ability, this weapon does not
grant it.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, inflict moderate wounds; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 770,310 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 385,310 gp + 17,700
XP. </p>
    <a id="chaosbringer"></a><p><span style={{
        "fontWeight": "bold"
      }}>Chaosbringer:</span> This +6
greataxe of anarchic power grants its wielder the ability to fly into a
rage (identical to a barbarian&rsquo;s rage) once per day (or one
additional
time per day if the wielder already has the rage class feature). If the
wielder has the greater rage class feature, the weapon also grants the
wielder the Incite Rage epic feat.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, rage, mass charm monster; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 4,025,350 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create: </span>2,012,850 gp +
50,250 XP. </p>
    <a id="elven-greatbow"></a><p><span style={{
        "fontWeight": "bold"
      }}>Elven Greatbow:</span> In the
hands of any nonelf, this bow performs only as a +2 composite longbow.
In the hands of an elf, this weapon functions as a +5 composite longbow
of unerring accuracy with a Strength bonus that matches its elven
wielder&rsquo;s current Strength at all times. Furthermore, any arrows
loosed
from the bow are considered keen, regardless of the enhancement bonus
of the arrow fired.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, bull&rsquo;s strength, keen
edge,
true seeing; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
2,900,400 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
1,450,400 gp + 39,400 XP. </p>
    <a id="everwhirling-chain"></a><p><span style={{
        "fontWeight": "bold"
      }}>Everwhirling Chain:</span>
This +4 defending everdancing spiked chain of speed continuously
twitches in its wielder&rsquo;s hands. The wielder of the everwhirling
chain
can use it to make any number of attacks of opportunity per round (as
if he or she had the Improved Combat Reflexes feat).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, animate objects, haste, shield
(or shield of faith); <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
5,220,325 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
2,610,325 gp + 52,200 XP. </p>
    <a id="finaldeath"></a><p><span style={{
        "fontWeight": "bold"
      }}>Finaldeath:</span> This +5
undead dread ghost touch morningstar also grants its wielder immunity
to energy drain attacks. Furthermore, if its wielder is capable of
turning undead, he or she gains the Positive Energy Aura feat.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 22nd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, death ward, plane shift, summon
monster IX, creator must be able to turn undead; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 3,580,308 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 1,790,308 gp +
45,800 XP. </p>
    <a id="gripsoul"></a><p><span style={{
        "fontWeight": "bold"
      }}>Gripsoul:</span> Gripsoul is
a +6 keen long-sword, but instead of dealing additional damage on a
critical hit, the weapon imprisons the victim in a gem set in the
pommel of the sword as per a binding spell heightened to 16th level (DC
30). The same is true of any blow that would otherwise kill a foe or
knock him or her unconscious. Only one creature can be so held, but the
wielder can release the bound soul at any time with a command word.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 27th; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, binding; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 1,856,500 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 934,500 gp + 28,440
XP. </p>
    <a id="holy-devastator"></a><p><span style={{
        "fontWeight": "bold"
      }}>Holy Devastator:</span> In
the hands of any character other than a paladin, this sword performs as
a +3 holy longsword. In the hands of a paladin, this weapon functions
as a +7 longsword of holy power and grants a +5 sacred bonus on the
wielder&rsquo;s saving throws against spells with the evil descriptor
or
spells cast by evil characters. If the paladin wielder smites evil with
the holy devastator, he or she adds twice his or her paladin level to
damage (rather than his or her paladin level).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, holy aura, holy smite, holy
word; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 4,620,315
gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 2,310,315
gp + 56,200 XP. </p>
    <a id="mace-of-ruin"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mace of Ruin:</span> This +7
heavy mace ignores the hardness or damage reduction of any object or
creature it strikes. Furthermore, the weapon can deal critical hits to
objects and constructs as if they were living creatures.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, disintegrate; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 1,000,312 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 500,312 gp + 20,000
XP. </p>
    <a id="quarterstaff-of-alacrity"></a><p><span style={{
        "fontWeight": "bold"
      }}>Quarterstaff of Alacrity:</span>
Both ends of this +5 quarterstaff of speed have equal enhancement and
special powers, meaning that it allows an additional attack with each
end every round. While the quarterstaff of alacrity is held, it grants
its wielder a +5 resistance bonus on Reflex saves. It also deflects
ranged weapons as if the wielder had the Deflect Arrows and Infinite
Deflection feats.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, protection from arrows, shield;
      <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 462,600
gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 231,600
gp + 14,620 XP. </p>
    <a id="souldrinker"></a><p><span style={{
        "fontWeight": "bold"
      }}>Souldrinker:</span> This +5
bastard sword bestows 2d4 negative levels on its target whenever it
deals damage, just as if its target had been struck by the energy drain
spell. Each negative level bestowed grants the wielder 5 temporary hit
points. One day after being struck, the subject must make a Fortitude
save (DC 25) for each negative level or lose a level. If this
sword&rsquo;s
power causes a character to have negative levels at least equal to his
or her current level, the character is instantly slain and the wielder
gains an additional 10 temporary hit points. Temporary hit points
gained from this weapon last for a maximum of 1 hour.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, Spell Focus (Necromancy),
energy drain; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
478,335 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
239,315 gp + 14,780 XP. </p>
    <a id="stormbrand"></a><p><span style={{
        "fontWeight": "bold"
      }}>Stormbrand:</span> This +4
thundering shocking burst greatsword allows its wielder to fly at will
(as the fly spell). Furthermore, the wielder can move normally
(including flying) even in the strongest winds. When the weapon is
drawn, the wielder gains electricity resistance 30 and sonic resistance
30.
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 21st; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, blindness/ deafness, call
lightning or lightning bolt, control winds, fly, protection from
energy; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span> 235,350
gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span> 117,850
gp + 12,350 XP. </p>
    <a id="unholy-despoiler"></a><p><span style={{
        "fontWeight": "bold"
      }}>Unholy Despoiler:</span> In
the hands of any character other than a blackguard, this sword performs
as a +3 unholy longsword. In the hands of a blackguard, this weapon
functions as a +7 longsword of unholy power and grants a +5 profane
bonus on the wielder&rsquo;s saving throws against spells with the good
descriptor or spells cast by good characters. If a blackguard wielder
smites good with the unholy despoiler, he or she adds twice his or her
blackguard level to damage (rather than his or her blackguard level).
      <span style={{
        "fontStyle": "italic"
      }}>Caster Level:</span> 23rd; <span style={{
        "fontStyle": "italic"
      }}>Prerequisites:</span> Craft Magic Arms and
Armor, Craft Epic Magic Arms and Armor, blasphemy, unholy aura, unholy
blight; <span style={{
        "fontStyle": "italic"
      }}>Market Price:</span>
4,650,315 gp; <span style={{
        "fontStyle": "italic"
      }}>Cost to Create:</span>
2,325,315 gp + 56,500 XP. </p>
    <p>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      